.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 30px;
}

.form-container1 {
  flex: 1;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 70%; /* Adjust the width as needed */
  max-width: 400px; /* Set a maximum width to maintain responsiveness */
  margin: auto; /* Center the form horizontally */
}

.image-container1 {
  flex: 1;
}

.login-image {
  width: 80%;
  height: auto;
  border-radius: 8px;
}

.form-container1 h5 {
  margin-bottom: 20px;
}

.text-field {
  margin-bottom: 16px;
}

.submit-button {
  margin-top: 16px;
}

.logo {
  display: block; /* Ensure the logo behaves properly */
  margin: 0 auto 20px; /* Center the logo horizontally and add some space at the bottom */
  max-width: 100%;
  height: auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .container1 {
    flex-direction: column; /* Change flex direction to stack elements vertically */
  }

  .form-container1 {
    width: 90%; /* Adjust width for smaller screens */
  }
}

.login-btn {
  background-color: #0d2333;
  width: 6rem;
}

.tag_line_thin,
.tag_line_bold {
  color: #6caaae;
  font-size: 2.5em;
  line-height: 1em;
  font-family: 'Philosopher', sans-serif;
}

.tag_line {
  margin-top: 5rem;
  margin-bottom: 4rem;
}

.book_demo {
  padding: 1rem 3rem;
  text-transform: uppercase;
  border: 1.5px solid #6caaae;
  border-radius: 0;
  background-color: #6caaae;
  font-weight: bold;
  color: white;
  box-shadow: 15px 5px rgba(0, 0, 0, 0.1);
}

.para {
  color: #6caaae;
  overflow-wrap: break-word;
  padding-right: 20px;
  width: 100%;
}

.image_container {
  margin-top: 2rem;
}

.image_spacing {
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .login-btn {
      margin-left: 1rem;
  }
}

/* .navbar-brand {
  margin-top: 2rem;
} */

.hospital-img {
  margin-top: 80px;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

select,
input,
textarea {
  width: 100%;
  height: 32px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

textarea {
  resize: vertical;
}

.custom-login-form {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensures it takes at least the full viewport height */
}
.Dropdown {
  max-height: 100px; /* Set your desired height here */
  overflow-y: auto;
  max-width: 90%;
  box-shadow: 0 0 5px rgba(143, 140, 140, 0.2);
}
.addlink{
  text-decoration: none;
  cursor: pointer;
  color: #428bca;
    background-color: transparent;
  
}
.form-erroremployee{
  font-size: 0.9rem;
 color: #b22b27;

 }
 .form-erroremployees{
  font-size: 1.5rem;
 color: #0dbe42;

 }
 .form-errors{
  font-size: 1.5rem;
 color: #b22b27;

 }
 .form-error{
  font-size: 0.9rem;
  color: #b22b27;

 }
/* 
.mt-3 {
  display: flex;
  justify-content: center;

} */

.custom-login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.custom-login-form .form-control {
  margin-bottom: 15px;
}

.custom-login-form .btn-primary {
  background-color: #007bff;
  border: none;
}

.custom-login-form .btn-primary:hover {
  background-color: #0056b3;
}

.custom-login-form .btn-link {
  color: #007bff;
}

.custom-login-form .btn-link:hover {
  text-decoration: underline;
}

.reports {
    display: flex;
    height: 90vh;
    font-size: 1rem;
  }
  .modal-container {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content:flex-start;
    transition: 0.4s;
    margin-left: 16%;
    margin-right: 0%;
    flex-grow: 1;
    margin-top: 0px;
    overflow-y: scroll;
    padding: 0px;
   padding-bottom: 3.5rem;
  }
  .title{
    height: auto;
    background-color:#fff;
    border-bottom: none;
  width: 100%;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 70px;
  z-index: 400;
  }
  .title1{
    display: flex;
    margin-left: 20px;
    width: 20%;
  }
  .container2{
    padding: 0px;
    padding-left: 0px;
    width: 100%;
  }
  
  .input-employeecontainer{
    display: flex;
    flex-direction: row;
    padding: 4px;
  }
  .input-blockemployee {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    transition: 0.3s;
  margin-right: 40px;
  width: 35vw;
  }
  .input-labelemployee {
    font-size: 14px;
   width: 180px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #141414;
    transition: 0.3s;
    margin-top: 9px;
  }
  .leftone:after {
    content: " *";
    color: red;
  }
  .inputerroremployee{
    width:15vw;
     display: flex;
     flex-direction: column;
     
   }  
   .custom-login-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
  }
  /* 
  .mt-3 {
    display: flex;
    justify-content: center;
  
  } */
  
  .custom-login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .custom-login-form .form-control {
    margin-bottom: 15px;
    margin-left: 12%;
  }
  
  .custom-login-form .btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  .custom-login-form .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .custom-login-form .btn-link {
    color: #007bff;
  }
  
  .custom-login-form .btn-link:hover {
    text-decoration: underline;
  }
  
  /* .reports {
      display: flex;
      height: 90vh;
      font-size: 1rem;
    } */
    /* .modal-container {
      width: 100%;
      background: #fff;
      display: flex;
      flex-direction:column;
      align-items: flex-start;
      justify-content:flex-start;
      transition: 0.4s;
      margin-left: 16%;
      margin-right: 0%;
      flex-grow: 1;
      margin-top: 0px;
      overflow-y: scroll;
      padding: 0px;
     padding-bottom: 3.5rem;
    } */
    .title{
      height: auto;
      background-color:#fff;
      border-bottom: none;
    width: 100%;
    border: 1px solid #eee;
    box-sizing: border-box;
    padding: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 70px;
    z-index: 400;
    }
    .title1{
      display: flex;
      margin-left: 20px;
      width: 20%;
    }
    .container2{
      padding: 0px;
      padding-left: 0px;
      width: 100%;
    }
    
    .input-employeecontainer{
      display: flex;
      flex-direction: row;
      padding: 4px;
    }
    .input-blockemployee {
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      transition: 0.3s;
    margin-right: 40px;
    width: 35vw;
    }
    .input-labelemployee {
      font-size: 14px;
     width: 180px;
      font-weight: 500;
      letter-spacing: 0.7px;
      color: #141414;
      transition: 0.3s;
      margin-top: 9px;
    }
    .leftone:after {
      content: " *";
      color: red;
    }
    .inputerroremployee{
      width:15vw;
       display: flex;
       flex-direction: column;
       
     }  
     /* .form-header{
      background-color:#1e9ef2;
     } */
  
     .head{
      background-color: #e6e5e5;
      padding: 0px;
      
     }
     .container-fluid{
      padding-left: 0px;
      padding-right: 0px;
     }
     .custom-row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .row>* {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .inputs input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #252424;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .appointment select {
    display: block;
    width: 60%;
    padding: 0.2rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #252424;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .appointment textarea {
    display: block;
    width: 60%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #252424;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .appointment input{
    display: block;
    width: 60%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #252424;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .dashboard-bg{
    background: #f6f7f6;
  }
  
  .grid-tablet{
    margin-left: 0vw;
    display: grid;
    gap: 1rem;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    padding: 1rem;
  }
  .grid-tablets{
    display: grid;
    padding: 0rem 1.5rem 1rem;
    color: #ffff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  #num-head{
    font-size: 30px;
    font-weight: bold;
  }
  #text-subhead{
    font-size: 15px;
    flex-direction: row-reverse;
    font-weight:400;
  }
  .tab-1{
    background-color: #3ec1a4;
  }
  .tab-2{
    background-color: #f2a72e;
  }
  .tab-3{
    background-color: #e96052;
  }
  .tab-4{
    background-color: #4aa5de;
  }
  .tab-5{
    background-color: #7d4ad6;
    /* color: #32424f; */
  }
  .calendarWrap {
    display: inline-block;
    position: relative;
    
  }
  .calendarWrap input[type=text] ,.calendarWrap input[type="number"],
  .calendarWrap input[type="email"],.calendarWrap input[type="date"],.calendarWrap select[type="text"] {
   width:215px;
   height: 75%;
   padding: 7px 12px;
   margin: 5px ;
   display: inline-block;
   border: 1px solid #ccc;
   border-radius: 4px;
   box-sizing: border-box;
   
  }
  .calendarElement {
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
    top: 40px;
    border: 1px solid #ccc;
    z-index: 999;
  }
  .container2{
    padding: 0px;
    padding-left: 0px;
    width: 100%;
  
  }
  .buttonDisplay{
    display: flex;
    width: 100%;
    justify-content: space-between;
   
  }
  .download{
    height: 35px;
    width: 35px;
    margin-right: 27px;
    color: #268ddd;
    font-size: 20px;
    cursor: pointer;
  }
  .logoutbutton {
    background-color: #f67638;
    color: white;
    border: none;
    padding: 0.6rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: -4px;
  }
  
  
  #tooltip{
    position:relative;
    cursor:pointer;
    padding:7px;
    font-size:14px;
    padding-left: 2px;
    
  
  }
  .allbuttonget{
    padding: 0.63rem ;
    outline: none;
    border: 0;
    color: #fff;
    border-radius: 5px;
    background: #f67638;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    transition: 0.3s;
    cursor: pointer;
    font-size: 13px;
    font-family: "Nunito", sans-serif;
    
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    font-size: 12px;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .tooltips {
    position: relative;
    display: inline-block;
  }
  
  .tooltips .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 100%;
    transform: translateX(10px) translateY(-50%);
    opacity: 0;
    font-size: 12px;
    transition: opacity 0.3s;
  }
  
  .tooltips .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
  }
  
  .tooltips:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  #supplyitem {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.8rem;
  }
  
  #supplyitem td, #supplyitem th {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }
  
  #supplyitem tr:nth-child(even){background-color: #f2f2f2;}
  
  #supplyitem tr:hover {background-color: #ddd;}
  
  #supplyitem th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #f67638;
    color: white;
    text-align: center;
  }
  @media (min-width: 1000px) {
    .grid-tablet { grid-template-columns: repeat(4, 1fr);}
  }
  @media (max-width: 1000px) {
    .grid-tablet { grid-template-columns: repeat(3, 1fr);}
  }
  @media (max-width: 800px) {
    .grid-tablet { grid-template-columns: repeat(3, 1fr);}
  }
  @media (max-width: 750px) {
    .grid-tablet { grid-template-columns: repeat(2, 1fr);}
  }
  @media (max-width: 440px) {
    .grid-tablet { grid-template-columns: repeat(1, 1fr);}
  }
  .custom-mui-datatable table {
    /* Ensure borders collapse properly */
   width: 98%;
   font-size: 12px;
   box-shadow: none !important;
   margin-left: 10px;
 }
 
 .custom-mui-datatable td,
 .custom-mui-datatable th {
   padding: 10px 0.3px;
   font-size: 0.9rem;
   width: 12.5%;
 }
 .tss-1akey0g-MUIDataTableHeadCell-data {
  font-weight: 700 !important;
}
 .css-1w1rijm-MuiButtonBase-root-MuiButton-root {
   padding: 8px 0px !important;
   margin-left: 0px !important;
}
 .css-11mde6h-MuiPaper-root {
   
   box-shadow: none !important; 
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
 text-transform: none !important;
}

 .patient-new{
   /* max-width: 850px; */
   max-width: 100%;
 }
 .hospital-new{
  max-width: 1000px;
 }
 .form-control{
   display: block;
  /* width: 90% !important; */
   width: 73% !important;
   height: 32px;
  padding: 5px 8px;
   font-size: 1rem;
   line-height: 1.6;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da !important;
   border-radius: 2px !important;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 }
 .primary-info {
   margin: 0 -20px;
   padding: 28px 20px 10px;
}
.header{
 border-bottom: 1px solid #eee;
 height: 64px;
 overflow: visible;
 padding-top: 10px;
}
.DropdownItem:hover {
 background-color: #2fa2e4; /* Change the background color to blue on hover */
 /* Apply a box shadow on hover */
 cursor: pointer;
 color:white; /* Change the cursor to a pointer on hover */
}
.remove{
 padding: 0px !important;
}
/* .css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  border: none !important;
  padding: 0px 0px 7px 0px !important;
} */
.tss-1akey0g-MUIDataTableHeadCell-data {
  font-weight: 700 !important;

}
 /* .css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  border: none !important;
  padding: 0px 0px 7px 0px !important;
}  */
/* Allow header cells to adjust width based on content */
.custom-mui-datatable {
  font-family: Inter,"Source Sans Pro",Helvetica,Arial,sans-serif !important;

}

.css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px !important;
  border: none !important;
}
.css-1w7yxsu-MuiAutocomplete-root {
  width: 242px !important;
}
.css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
  /* max-width: 300px !important; */
}
.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 5px 0px 0px 0px !important;
}
.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 11px !important;
}

@media screen and (max-width: 500px) {
  .mx-2 {font-size: 13px;}
  .mxs-2 {margin-right: 30px;}
  .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon{
    width: 200px !important;
  }
 .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon:nth-last-of-type(2){
  display: none
  
  ;
 }
}
/* .MuiTableCell-root{ */
  /* width:300px;
  word-break: break-all; */
  /* column-width: 20rem; */
/* } */

.css-5bcq5j{
  padding: 6px 0px !important;
  text-transform: none !important;
}
.css-ao8rxk {
  background-color: rgb(255, 255, 255) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  box-shadow: none !important; 
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
  min-width: 20px !important;
}
.tab-buttons {
  display: flex;
  margin-bottom: 20px;
}

.button-link {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.button-link.active {
  background-color: #007bff;
  color: #fff;
}

.tab-content {
  border: 1px solid #ccc;
  padding: 20px;
}
@media only screen and (min-width: 1450px ) and (max-width:2000px){
  .css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root {
    width: 240px;
  } 
  .css-1jyciw5 .MuiOutlinedInput-root {
    width: 220px !important;
}
}
@media only screen and (min-width: 1399px ) and (max-width:1449px){
  .css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root {
    width: 240px;
  }
  .css-1jyciw5 .MuiOutlinedInput-root {
    width: 268px !important;
}
}
@media only screen and (min-width: 1300px ) and (max-width:1398px){
  .css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root {
    width: 280px;
  }
  .css-1jyciw5 .MuiOutlinedInput-root {
    width: 245px !important;
}
}
@media only screen and (min-width: 1100px ) and (max-width:1298px){
  .css-1w7yxsu-MuiAutocomplete-root .MuiOutlinedInput-root {
    width: 240px;
  }
  .css-1jyciw5 .MuiOutlinedInput-root {
    width: 206px !important;
}
}
.css-1jyciw5 .MuiOutlinedInput-root .MuiAutocomplete-input{
  padding: 0.375px 0.75px !important;
}

.css-segi59{
  width: 19vw !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 6px !important;
  border: none !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 74% !important;
}
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 0px !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}
.css-18nc3u2 .MuiOutlinedInput-root {
  padding: 0px  !important;
}
.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 6px !important;
  border: none !important;
}